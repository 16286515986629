<div *ngIf="!(isLoggedIn$ | async)">
  This may be an intro page or a redirect to another page introducing DSM and providing login link
<button (click)="signIn()">Sign in</button>
<router-outlet></router-outlet>
</div>

<div class="mat-app-background" *ngIf="!!(isLoggedIn$ | async)" style="width: 100%;">

  <!-- Top Navigation -->
  <top-navbar [isSidenavOpen]="isExpanded" (sidenavToggleEvent)="getActionFromNavbar($event)"></top-navbar>
  <mat-drawer-container class="fullscreen-container mat-typography" autosize>
    
    <!-- Side Navigation -->
    <mat-drawer #dsmSideNavigation mode="side" disableClose="false" opened="true" class="left-sidenav">
      <mat-nav-list class="nav-container" [ngClass]="{'opened': !!(sideNavOpened$ | async)}">
        <mat-list-item class="customLI" *ngFor="let sideNavigationItem of sideNavigationItems" 
                      routerLink="{{ sideNavigationItem.routerLink }}" 
                      routerLinkActive="nav-item-active"
                      [routerLinkActiveOptions]="{exact: false}">
                <!-- Navigation closed-->
                <div *ngIf="!(sideNavOpened$ | async) else navigationOpened">
                  <mat-icon class="nav-item" [svgIcon]="'base:'+sideNavigationItem.icon"></mat-icon>
å
                </div>
                <!-- Navigation opened -->
                <ng-template #navigationOpened>
                  <mat-icon class="nav-item" [svgIcon]="'base:'+sideNavigationItem.icon"></mat-icon>
                  {{ sideNavigationItem.labelI18nKey | translate }}
                </ng-template>
          <!-- <h4 mat-line *ngIf="!!(sideNavOpened$ | async)">
            <mat-icon class="nav-item" [svgIcon]="'base:'+sideNavigationItem.icon"></mat-icon> 
          </h4> -->
        </mat-list-item>

      </mat-nav-list>
      

      <div class="version-info" *ngIf="!!(sideNavOpened$ | async)">
        DSM v{{ dsmVersion }}
      </div>
      

    </mat-drawer>    
  
    <!-- Page Content -->
    <ng-container *ngIf="breadCrumbData">
      <cl-breadcrumb [breadCrumbData]="breadCrumbData" ></cl-breadcrumb>
    </ng-container>
    

    <div #dsmPageContent id="dsmPageContent" class="main-content-area">
      <router-outlet class="router-flex"></router-outlet>
    </div>
  </mat-drawer-container>
</div>

