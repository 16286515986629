import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './shared/components/callback/callback.component';
import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
//import { InterceptorService } from './shared/services/interceptor.service';
import { AppAuthGuard } from './shared/guard/app-auth.guard';
import { IntroAuthGuard } from './shared/guard/intro-auth.guard';

const routes: Routes = [
  { path: "callback", component: CallbackComponent },

  { path: '', redirectTo: 'intro', pathMatch: 'full' },

  { path: "app/account",
      loadChildren: () => import ('./features/account/account.module').then(m => m.DsmAccountModule )
  },

  { path: 'app', canActivateChild: [AppAuthGuard], children: [

    // Feature: customers 
    { 
      path: "customers",
      loadChildren: () => import ('./features/customers/customers.module').then(m => m.DsmCustomersModule )
    },

    // Feature: employees
    { 
      path: "employees",
      loadChildren: () => import ('./features/employees/employees.module').then(m => m.DsmEmployeeModule )
    },

    // Feature: incoming-topics
    { 
      path: "incoming-topics",
      loadChildren: () => import ('./features/incoming-topics/incoming-topics.module').then(m => m.DsmIncomingTopicsModule )
    },

    // Feature: machine
    { 
      path: "machines",
      loadChildren: () => import ('./features/machines/machines.module').then(m => m.DsmMachinesModule ),
      canActivateChild: [AppAuthGuard]
    },

    // Feature: servicevan
    { 
      path: "servicevans",
      loadChildren: () => import ('./features/servicevans/servicevans.module').then(m => m.DsmServiceVansModule ),
      canActivateChild: [AppAuthGuard]
    },

    // Feature: technical-documentation
    { 
      path: "technical-documentation",
      loadChildren: () => import ('./features/technical-documentation/technical-documentation.module').then(m => m.DsmTechnicalDocumentationModule ),
      canActivateChild: [AppAuthGuard]
    },
  ]},

  { path: 'intro', canActivateChild: [IntroAuthGuard], children: [

    // Intro: start 
    { 
      path: "",
      loadChildren: () => import ('./intro/intro.module').then(m => m.DsmIntroModule )
    },
  ]},


  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
/*    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }*/
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  constructor() {}
    
}
