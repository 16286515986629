import { ActionReducerMap, ActionReducer, MetaReducer, createFeatureSelector, createSelector } from '@ngrx/store';
import * as RouterStore from '@ngrx/router-store';
import { environment } from '../../../environments/environment';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */


import { LayoutReducer, LayoutEffects } from './layout';
import { AccountReducers, AccountEffects } from './account';
import { NotificationEffects, NotificationReducer } from './notification';
import { StoreConstants } from '../models/store.model';



export type StateError = Error | string | null;

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  rsAccount: AccountReducers.AccountState;
  rsLayout: LayoutReducer.State;
  rsNotifications: NotificationReducer.State;

//  jobs: JobReducer.JobState;
}

/**
 * Re-export all of our shared Effects.
 *
 * Registered in the `core` ngModule
 */
export const effects = [
  AccountEffects,
  LayoutEffects,
  NotificationEffects,
];

 export const reducers: ActionReducerMap<State, any> = {
  rsAccount: AccountReducers.reducer,
  rsLayout: LayoutReducer.reducer,
  rsNotifications: NotificationReducer.reducer,
 };




