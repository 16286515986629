import { FormsModule } from '@angular/forms';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MatIconRegistry } from "@angular/material/icon";
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { ErrorInterceptor } from './shared/services/error.interceptor';
import { CommonModule } from '@angular/common';
import { DsmSharedModule } from './shared/shared.module';
import { DsmCoreModule } from './shared/core.module';

import { AppComponent } from './app.component';

// services
import { DsmApiCall } from './shared/services/dsm-api-call.service';
import { AuthServiceWrapper } from './shared/services/auth.service';

// LOCALES based on languages available in CLAAS ID
import localeCzech from '@angular/common/locales/cs'
import localeDenmark from '@angular/common/locales/da'
import localeGermany from '@angular/common/locales/de';
import localeSpain from '@angular/common/locales/es';
import localeEstonia from '@angular/common/locales/et';
import localeEnGb from '@angular/common/locales/en-GB';
import localeFrance from '@angular/common/locales/fr';
import localeItaly from '@angular/common/locales/it';
import localeLatvia from '@angular/common/locales/lv';
import localeLithuania from '@angular/common/locales/lt';
import localeDutch from '@angular/common/locales/nl';
import localeNorway from '@angular/common/locales/nb'; // claas id returns 'no'
import localeRomania from '@angular/common/locales/ro';
import localePoland from '@angular/common/locales/pl';
import localePortugal from '@angular/common/locales/pt';
import localeSlovakia from '@angular/common/locales/sk';
import localeSweden from '@angular/common/locales/sv';
import localeUkraine from '@angular/common/locales/uk';


import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { DsmAccountModule } from './features/account/account.module';
import { DsmAccountRoutingModule } from './features/account/account.routing';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { MbscModule } from '@mobiscroll/angular';


registerLocaleData(localeCzech);
registerLocaleData(localeDenmark);
registerLocaleData(localeGermany);
registerLocaleData(localeSpain);
registerLocaleData(localeEstonia);
registerLocaleData(localeEnGb);
registerLocaleData(localeFrance);
registerLocaleData(localeItaly);
registerLocaleData(localeLatvia);
registerLocaleData(localeLithuania);
registerLocaleData(localeDutch);
registerLocaleData(localeNorway);
registerLocaleData(localeRomania);
registerLocaleData(localePoland);
registerLocaleData(localePortugal);
registerLocaleData(localeSlovakia);
registerLocaleData(localeSweden);
registerLocaleData(localeUkraine);

const PROVIDERS = [
  // SERVICES
  DsmApiCall,
  AuthServiceWrapper,
//  AccountService,
  { provide: localeCzech, useValue: "cs" },
  { provide: localeDenmark, useValue: "da" },
  { provide: localeGermany, useValue: "de" },
  { provide: localeSpain, useValue: "es" },
  { provide: localeEstonia, useValue: "et" },
  { provide: localeEnGb, useValue: "en-GB" },
  { provide: localeFrance, useValue: "fr" },
  { provide: localeItaly, useValue: "it" },
  { provide: localeLatvia, useValue: "lv" },
  { provide: localeLithuania, useValue: "lt" },
  { provide: localeDutch, useValue: "nl" },
  { provide: localeNorway, useValue: "no" },
  { provide: localeRomania, useValue: "ro" },
  { provide: localePoland, useValue: "pl" },
  { provide: localePortugal, useValue: "pt" },
  { provide: localeSlovakia, useValue: "sk" },
  { provide: localeSweden, useValue: "sv" },
  { provide: localeUkraine, useValue: "uk" },
];

import config from './app.config';


@NgModule({
  declarations: [AppComponent],
  imports: [ 
    FormsModule,  
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    DsmSharedModule,
    DsmCoreModule,
    DsmAccountModule,
    DsmAccountRoutingModule,
    OktaAuthModule,
    MbscModule,
  ],
  exports: [],
  providers: [
    PROVIDERS, MatIconRegistry,
{ 
  provide: OKTA_CONFIG, 
  useFactory: () => {
    const oktaAuth = new OktaAuth(config.oidc);
    return {
      oktaAuth,
      // onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
      //   console.log(oktaAuth)
      //   const triggerLogin = async () => {
      //     await oktaAuth.signInWithRedirect();
      //   };
      //   if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
      //     // App initialization stage          
      //     console.log("Nicht eingeloggt");
      //     triggerLogin();
      //   } else {
      //     // Ask the user to trigger the login process during token autoRenew process
      //     console.log("Ask the user to trigger the login process during token autoRenew process");
      //     // const modalService = injector.get(SuiModalService);
      //     // modalService
      //     //   .open(new ConfirmModal("Do you want to re-authenticate?", "Auth required", "Yes", "No"))
      //     //   .onApprove(triggerLogin)
      //     //   .onDeny(() => {});
      //   }
      // }  
    }
  }
}

/*    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },*/
],
  bootstrap: [AppComponent]
})
export class AppModule { }


