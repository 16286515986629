export interface SideNavigationItem {
    icon:           string;
    labelI18nKey:   string;
    routerLink:     string;
};


export const SideNavigationItems: SideNavigationItem[] = [
    {
        icon:           'incoming-topics.svg',
        labelI18nKey:   'global.navigation.incomingTopics',
        routerLink:     '/app/incoming-topics'
    },
    // {
    //     icon:           'work-orders.svg',
    //     labelI18nKey:   'global.navigation.jobs',
    //     routerLink:     '/workorders'
    // },
    {
        icon:           'machines.svg',
        labelI18nKey:   'global.navigation.machines',
        routerLink:     '/app/machines'
    },
    // {
    //     icon:           'calendar.svg',
    //     labelI18nKey:   'global.navigation.calendar',
    //     routerLink:     '/calendar'
    // },
    {
        icon:           'employees.svg',
        labelI18nKey:   'global.navigation.employees',
        routerLink:     '/app/employees'
    },
    {
        icon:           'customers.svg',
        labelI18nKey:   'global.navigation.customers',
        routerLink:     '/app/customers'
    },
    {
        icon:           'servicevans.svg',
        labelI18nKey:   'global.navigation.servicevans',
        routerLink:     '/app/servicevans'
    },
    {
        icon:           'technical-documentation.svg',
        labelI18nKey:   'global.navigation.technicalDocumentation',
        routerLink:     '/app/technical-documentation'
    }


];