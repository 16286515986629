import { Component, OnInit, OnDestroy, ViewChild, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, Subscription, tap } from 'rxjs';

import { AuthServiceWrapper } from './shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import * as RootState from './shared/state';

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { SideNavigationItem, SideNavigationItems } from './shared/components/navbar/side-navbar.source';

import { LayoutReducer, LayoutSelectors } from './shared/state/layout';
import { BaseActions } from './shared/state/base';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import {OktaAuth, AuthState, IDToken } from '@okta/okta-auth-js';
//import { AccountActions, AccountSelectors } from './features/account/state';
import * as versioning from './shared/changelog/version';
import { BreadCrumbData } from './shared/models/layout.model';
import { AccountActions } from './shared/state/account';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy{

  @ViewChild('dsmSideNavigation') public drawer: MatDrawer;
  public sideNavOpened$: Observable<boolean> = this.store.select(LayoutSelectors.getSideNavOpened);
  public sideNavigationItems: SideNavigationItem[] = SideNavigationItems;
  
  public dsmVersion: string = versioning.dsmVersion;


  isExpanded: boolean = false;

  private isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoggedIn$: Observable<boolean> = this.isLoggedInSubject.asObservable();
  
  public breadCrumbData$: Observable<BreadCrumbData> = this.layoutStore.select(LayoutSelectors.getBreadCrumbData);
  public breadCrumbData: BreadCrumbData;
  public breadCrumbSub: Subscription;


constructor(
  private activatedRoute: ActivatedRoute,
  private auth: AuthServiceWrapper,
  private translator: TranslateService,
  private store: Store <RootState.State>,
  private iconRegistry: MatIconRegistry,
  private sanitizer:DomSanitizer,
  private _router: Router,  
  private layoutStore: Store<LayoutReducer.State>,
  private change: ChangeDetectorRef,
  public authStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth){
  }


  @HostListener('window:load')
  private onInitalPageLoad(): void {

    setTimeout(() => {
      this.authStateService.authState$.pipe(
        filter((s: AuthState) => !!s),
      ).subscribe((s: AuthState) => {
        this.isLoggedInSubject.next(s.isAuthenticated);
        if (!!s.isAuthenticated) {
          this.store.dispatch(new BaseActions.RehydrateApplicationState());
        } else {
          this.store.dispatch(new AccountActions.SessionLogout());
        }

        // trigger global rehydration of application state
//        this.store.dispatch(new BaseActions.RehydrateApplicationState());
      });
    }, 300);
  }

  ngOnInit(){
    // setTimeout(() => {
    //   this._checkLogin();
    // }, 300);

    this.sideNavigationItems.forEach((item: SideNavigationItem) => {
      this.iconRegistry.addSvgIconInNamespace('base', item.icon, this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sidenav/' + item.icon));
    });

    this.breadCrumbSub = this.breadCrumbData$.subscribe((data) => {
      this.breadCrumbData = data;
      this.change.detectChanges();
    });
  }

  ngOnDestroy(){
//    this.userProfile$.unsubscribe();
    this.breadCrumbSub?.unsubscribe();
  }

  getActionFromNavbar(event: any){ this.isExpanded = event; }


  openApp(app:any){
    if(app.href) { this._router?.navigate([app.href]); }
    else if (app.link) { window.location.href = app.link }
  };



  public async signIn() {
    await this.oktaAuth.signInWithRedirect();
  }

}
